.titulo{
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 20px;
}

.cont{
    align-items: center;
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
}

.tableCont{
    margin-top: 40px;
    background-color: aqua;
    width: 400px;
    height: 200px;
}