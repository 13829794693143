.main_container{
    background-color: rgba(238, 238, 238, 0.904);
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-top: 40px;
}

.buttonSave{
    background-color:rgba(238, 238, 238, 0.904) ;
    color:black;
    border-color: rgba(238, 238, 238, 0.904);
}
